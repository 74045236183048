.account {

  div.ant-typography {
      margin-bottom: 0;
  }
}

#form-category{
  .ant-picker-range{
    width: 100%;
  }
}